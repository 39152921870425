import React, { useEffect, useState } from "react";
import LayoutDefault from '../layout/Layout';
import "animate.css"

// IMAGES
import Dirt from '../../assets/images/Dirt.jpg';
import IT from '../../assets/images/customer-service.svg';
import QualityIssues from '../../assets/images/high-quality.svg';
import BackorderParts from '../../assets/images/maintenance.svg';
import Design from '../../assets/images/design.svg';
import ShippingRequest from '../../assets/images/fast-delivery.svg';
import MaintenanceRequest from '../../assets/images/maintenance-(1).svg';
import OwnTheUgly from '../../assets/images/request-(1).svg';
import InformationTech from '../../assets/images/atom.svg';
import AirTravelCar from '../../assets/images/PlaneCar.png';
import JobJar from '../../assets/images/piece-of-paper-and-pencil.png';
import Globe from '../../assets/images/globe.png'
import HR from '../../assets/images/new-employee.png'



document.title ="The K.A. Group Internal Support"; 

// SANITIZE HTML DATA
// var sanitizer = DOMPurify.sanitize;

const Home = ({

}) => {

    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!isActive);
    };

    const SmartText = ({ text, length = 151 }) => {
        const [showLess, setShowLess] = React.useState(true);
      
        if (text.length < length) {
          return <p>{text}</p>;
        }
      
        return (
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: showLess ? `${text.slice(0, length)}...` : text,
              }}
            ></p>
            <a
              style={{ color: "#fff", cursor: "pointer" }}
              onClick={() => setShowLess(!showLess)}
            >
              &nbsp;View {showLess ? "More" : "Less"}
            </a>
          </div>
        );
      };
      
 
    return (
        <>
            {/* <div className={ isActive ? 'overlay open' : 'overlay' } id="it-modal">
                <button className="close" onClick={ toggleClass }>&#10005;</button>
                <iframe width="70%" src="https://www.youtube.com/embed/AW1Hj4eSlIA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div> */}
            <LayoutDefault className="width-1200">
                <img  className='dirt-img' src={Dirt} alt="Dirt" />

                <section>
                    {/* <h1 style={{ marginBottom: '23px' }} className="animate__animated animate__fadeInLeft animate__fast">For Sale!!</h1>
                    <p className="animate__animated animate__fadeInLeft animate__fast">We've got a list of things we're selling that we want to give YOU first crack at!</p>
                    <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast">
                        <a style={{ marginBottom: '78.5px', marginTop: '0px',width: '200px'}} className="custom-btn" href="https://ktec.sharepoint.com/:l:/g/FLa_S2Iy9cdEgWzHCk9s7l8B_07471_4HRvm0H_GhuxFHA?e=ciaVVk" target="_blank" rel="noopener noreferrer">View List</a>
                        <a style={{ marginBottom: '78.5px', marginTop: '0px',width: '200px'}} className="custom-btn" href="https://forms.office.com/Pages/ResponsePage.aspx?id=JxF44ckTQUK_Ecq91KnG_dReXjpVN7xHoKgvyWCUsIFUQjVISzFBTTJVTEU2MU1WSk1MUkpKMEJYRi4u" target="_blank" rel="noopener noreferrer">Post Item</a>
                    </div> */}

                    <h1 className="animate__animated animate__fadeInLeft animate__fast">Support Links</h1>

                    <div className="block-row row">
                        <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                            <img src={IT} alt="I.T Helpdesk" />
                            <h2>IT Helpdesk</h2>
                            <SmartText text="Literally any tech question/problem ever." />
                            <a className="custom-btn" href="https://forms.clickup.com/f/dhthx-17067/ZIB2CG18JF3PJZT6DX?Destination%20List=IT" target="_blank" rel="noopener noreferrer">Submit Request</a>
                            {/* <button className="custom-btn" id="video-modal-btn" type="button" onClick={ toggleClass }>Need a Laugh?</button> */}
                        </div>

                        <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                            <img src={InformationTech} alt="Information Technology" />
                            <h2>Information Technology</h2>
                            <SmartText text="Tech setup requests for new employees and ClickUp user creation." />
                            <a style={{ marginTop: '104px' }} className="custom-btn" href="/information-technology" rel="noopener noreferrer">View More</a>                        
                        </div>

                        <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                            <img src={HR} alt="Human Resources" />
                            <h2>Human Resources</h2>
                            <SmartText text="Time Off Requests and all other things HR/Safety." />
                            <a style={{ marginTop: '104px' }} className="custom-btn" href="/human-resources" rel="noopener noreferrer">View More</a>                        
                        </div>

                    </div>

                    <div className="block-row row">
                        <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                            <img src={BackorderParts} alt="Purchasing & Inventory" />
                            <h2>Purchasing & Inventory</h2>
                            <SmartText text="All Purchasing and Inventory related tickets." />
                            <a className="custom-btn" href="/Inventory" rel="noopener noreferrer">View More</a>  
                            {/* <a className="custom-btn" href="https://forms.clickup.com/f/dhthx-17107/RH6WU0NKZZ1CT1IR6M?Destination%20List=InventoryPurchasing" target="_blank" rel="noopener noreferrer">Submit Request</a> */}
                        </div>

                        <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                            <img src={ShippingRequest} alt="Shipping Request" />
                            <h2>Logistics Requests</h2>
                            <SmartText text="Submit basic shipment requests, intercompany transfers, and track internal cross border trips." />
                            <a className="custom-btn" href="/logistics" rel="noopener noreferrer">View More</a>                        
                        </div>

                        <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                            <img src={MaintenanceRequest} alt="Maintenance Request" />
                            <h2>Maintenance Request</h2>
                            <SmartText text="Notify the maintenance team for any equipment or building related issues." />
                            <a className="custom-btn" href="https://requests.onupkeep.com/#/e8nockCxdg/work-orders/create" target="_blank" rel="noopener noreferrer">Submit Request</a>                        
                        </div>  
                    </div>

                    <div className="block-row row">
                        <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                            <img src={Design} alt="Design Request" />
                            <h2>Design & Production Processing</h2>
                            <SmartText text="Submit any production processing issues, design ideas, jig requests, or production order requests." />
                            <a className="custom-btn" href="/design-production-processing" rel="noopener noreferrer">View More</a>
                        </div>
                        <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                            <img src={QualityIssues} alt="Quality Control" />
                            <h2>Quality Control</h2>
                            <SmartText text="Whether it's production or shipping, we pride ourselves in having top-notch quality. Notify us so we can prevent future issues." />
                            <a className="custom-btn" href="/quality" rel="noopener noreferrer">View More</a>
                        </div>

                        {/* <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                            <img src={OwnTheUgly} alt="Own The Ugly Session Request" />
                            <h2>Own The Ugly Session Request</h2>
                            <SmartText text='Book a session with Ed Peters "Our People Guru", to hash out fustrations within your department or discuss desires for growth and improvement.' />
                            <a className="custom-btn" href="https://forms.clickup.com/f/dhthx-18427/X4S1F1P9DOV2IX8DZ2?Task%20Name=Own%20the%20Ugly%20Session%20Request&Destination%20List=OwntheUglyRequest" target="_blank" rel="noopener noreferrer">Submit Request</a>                        
                        </div> */}

                        {/* <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                            <img src={JobJar} alt="Add Task Mod Duties Job Jar" />
                            <h2>Add Task to Modified Duties Job Jar</h2>
                            <SmartText text="If you've got tasks that aren't high urgency, you can add them to this list for when we have people that need modified work" />
                            <a className="custom-btn" href="https://forms.clickup.com/14214717/f/dhthx-29711/8AHQ2FGCDA341W22PK" target="_blank" rel="noopener noreferrer">Submit Task</a>                        
                        </div> */}

                        <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                            <img src={Globe} alt="Improvement Idea" />
                            <h2>Improvement Idea</h2>
                            <SmartText text="Whether streamlining processes, optimizing workflows, or implementing best practices, your ideas are invaluable to our continuous improvement efforts." />
                            <a className="custom-btn" href="https://forms.clickup.com/14214717/f/dhthx-31651/JBJMSYSO3M0WU8UNM8?Destination%20List=OpEx" target="_blank" rel="noopener noreferrer">Submit Task</a>                        
                        </div>

                    </div>

                    <div className="block-row row">
                        <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                            <img src={AirTravelCar} alt="Travel Authorization" />
                            <h2>Travel Authorizations</h2>
                            <SmartText text="Booking a work related trip? Submit a ticket to get your travel approved." />
                            <a className="custom-btn" href="https://forms.clickup.com/14214717/f/dhthx-25002/KUIZV5VMK7GJES5R2T?Authorization%20Type=Travel" target="_blank" rel="noopener noreferrer">Submit Request</a>                        
                        </div>
                        
                        <div className="column"></div>
    
                        {/* <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                            <img src={OwnTheUgly} alt="Suggestion Box" />
                            <h2>Suggestion Box</h2>
                            <SmartText text='Do you have an awesome idea? Let us know!' />
                            <a className="custom-btn" href="https://forms.office.com/r/w9dMykd4tL" target="_blank" rel="noopener noreferrer">Submit Idea</a>                        
                        </div> */}

                        <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                            <img src={JobJar} alt="Add Task Mod Duties Job Jar" />
                            <h2>Add Task to Modified Duties Job Jar</h2>
                            <SmartText text="If you've got tasks that aren't high urgency, you can add them to this list for when we have people that need modified work" />
                            <a className="custom-btn" href="https://forms.clickup.com/14214717/f/dhthx-29711/8AHQ2FGCDA341W22PK" target="_blank" rel="noopener noreferrer">Submit Task</a>                        
                        </div>

                        {/* <div className="column"></div> */}
                            {/* <img src={AirTravelCar} alt="Travel Authorization" />
                            <h2>Travel Authorizations</h2>
                            <SmartText text="Booking a work related trip? Submit a ticket to get your travel approved." />
                            <a className="custom-btn" href="https://forms.clickup.com/14214717/f/dhthx-25002/KUIZV5VMK7GJES5R2T?Authorization%20Type=Travel" target="_blank" rel="noopener noreferrer">Submit Request</a>                        
                        </div> */}
                    </div>

                </section>
            </LayoutDefault>
        </>
    );
}

export default Home;